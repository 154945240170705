import state from './state'
import api from './api'
import getters from './getters'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'
import useCondition from './useCondition'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const {
    destructCondition,
  } = useCondition()

  const getJobDetail = () => api.getJobDetail({
    idx: state.idx,
  }).then(response => {
    const resData = response.data.data

    state.memberType = resData.member_type
    state.memberIdx = resData.member_idx
    state.channel = resData.channel
    state.seedType = resData.seed_type
    state.seed = resData.seed
    state.state = resData.state
    state.meta = resData.meta
    state.condition = resData.condition
    state.createdAt = resData.created_at
    destructCondition()
    state.snapshot = resData
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getResultList = () => api.getResultList({ job_idx: state.idx }).then(response => {
    state.resultLogList = response.data.data
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', 'Fail to get result list')
  })

  const getJobMember = () => api.getJobMember({
    member_type: state.memberType,
    member_idx: state.memberIdx,
  }).then(response => {
    const resData = response.data.data
    state.memberName = resData.name
    state.memberEmail = resData.email
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', 'Fail to get member')
  })

  const queryPostList = () => api.queryPostList({
    result_idx_list: getters.resultIdxList,
  }).then(response => {
    state.postListByUsername = response.data.data
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', 'Fail to query posts')
  })

  const getTimelineList = () => api.getTimelineList({
    job_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', 'Fail to get timelines')
  })

  return {
    getJobDetail,
    getResultList,
    getJobMember,
    queryPostList,
    getTimelineList,
  }
}
