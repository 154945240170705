import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const turnOnSetSeedModal = () => {
    state.isVisibleSetSeedModal = true
  }
  const turnOffSetSeedModal = () => {
    state.isVisibleSetSeedModal = false
  }

  const setSeed = () => api.setSeed({ seed: state.seed }).then(() => {
    makeToast(
      'primary',
      'Success to set seed',
      'It may take some time for seed addition to fully complete. Please check again later.',
    )
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', 'Fail to set seed')
  })

  return {
    turnOnSetSeedModal,
    turnOffSetSeedModal,
    setSeed,
  }
}
