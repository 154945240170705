import axiosFrame from '@/utils/axiosFrame'

export default {
  getJobDetail: axiosFrame('get', '/manage/inf-recommend/job-detail/', null),
  createJob: axiosFrame('post', '/manage/inf-recommend/create-job/', null),
  updateJob: axiosFrame('post', '/manage/inf-recommend/update-job/', null),
  getResultList: axiosFrame('get', '/manage/inf-recommend/result-list/', null),
  queryExistsSeed: axiosFrame('post', '/manage/inf-recommend/query-exists-seed/', null),
  setSeed: axiosFrame('post', '/manage/inf-recommend/set-seed/', null),
  getJobMember: axiosFrame('get', '/manage/inf-recommend/job-member/', null),
  queryPostList: axiosFrame('post', '/manage/inf-recommend/query-post-list/', null),
  getResultExcel: axiosFrame('get', '/manage/inf-recommend/result-excel/', 'blob'),
  setResultIsUnsuitable: axiosFrame('post', '/manage/inf-recommend/set-result-is-unsuitable/', null),
  getTimelineList: axiosFrame('get', '/manage/inf-recommend/timeline-list/', null),
}
