import {
  reactive,
} from '@vue/composition-api'

import getStateOrigin from './stateOrigin'

export default reactive({
  filterParams: {},
  ...getStateOrigin(),
})
