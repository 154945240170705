<template>
  <BCard>
    <div>
      <InstaResultRow
        v-for="result in getters.resultLogItems"
        :key="result.idx"
        :result="result"
      />
    </div>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import InstaResultRow from './InstaResultRow.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BTable,
    InstaResultRow,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
