import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from './state'
import api from './api'
import getters from '@/views/member/inf-type/list/getters'

export default () => {
  const { makeToast } = useToast()

  // const searchParams = computed(() => ({
  //   ...state.filterParams,
  // }))

  const categoryOptions = [
    {
      label: 'TYPE',
      paramName: 'type',
    },
  ]

  const queryPostList = () => api.queryPostList({
    result_idx_list: getters.resultIdxList,
  }).then(response => {
    state.postListByUsername = response.data.data
  }).catch(() => {
    makeToast('danger', 'Fail to query posts')
  })

  const fetchInfTypeResult = idx => api.getResultList({ job_idx: idx })
    .then(response => {
      state.resultLogList = response.data.data
      queryPostList()
    }).catch(() => {
      makeToast('danger', 'Fail to get result list')
    })

  const search = params => {
    let idxVal = 0
    const typeText = params.type.toString()
    if (typeText === '스트릿패션') {
      idxVal = 116
    } else if (typeText === '강아지') {
      idxVal = 115
    } else if (typeText === '나이키') {
      idxVal = 117
    } else if (typeText === '안다르') {
      idxVal = 118
    } else {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }
    state.filterParams = {
      ...params,
    }
    // replaceRouterQuery(searchParams.value)
    // syncronizeParamsWithUrl(params)
    fetchInfTypeResult(idxVal)
  }

  return {
    categoryOptions,
    // fetchItems,
    search,
  }
}
