<template>
  <div>
    <TagbyFilterV2
        :initialParams="state.filterParams"
        :categoryOptions="categoryOptions"
        :leftWidth="3"
        @search="search"
    >
    </TagbyFilterV2>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <ResultCard />
    </template>
    <template #hidden>
      <ErrorReasonModal />
      <SetSeedModal />
    </template>
  </TagbyDetailLayout>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import ErrorReasonModal from './components/Modals/ErrorReasonModal.vue'
import SetSeedModal from './components/Modals/SetSeedModal.vue'
import ResultCard from './components/ResultCard/Main.vue'
import state from './state'
import getters from './getters'
import { resetState } from './useState'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    ErrorReasonModal,
    SetSeedModal,
    ResultCard,
    TagbyFilterV2,
  },
  setup() {
    resetState()

    const {
      search,
      categoryOptions,
    } = useItems()

    return {
      state,
      getters,
      search,
      categoryOptions,
    }
  },
})
</script>
