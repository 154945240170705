import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErrorReason from './useErrorReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const {
    getTimelineList,
  } = useFetch()

  const setResultIsUnsuitable = (resultIdx, isUnsuitable) => api.setResultIsUnsuitable({
    result_idx: resultIdx,
    is_unsuitable: isUnsuitable,
  }).then(() => {
    makeToast('primary', 'Successfully set the result as inappropriate.')
    getTimelineList()
  }).catch(err => {
    turnOnErrorReasonModal(err)
    makeToast('danger', 'Failed to set with inappropriate result.')
    const temp = state.resultLogList
    state.resultLogList = []
    setTimeout(() => {
      state.resultLogList = temp.map(r => {
        if (r.idx === resultIdx) {
          return {
            ...r,
            is_unsuitable: !isUnsuitable,
          }
        }
        return r
      })
    })
  })

  return {
    setResultIsUnsuitable,
  }
}
